<template>
  <v-container>
    <v-bottom-navigation absolute>
      <v-pagination
        class="py-1"
        v-model="page"
        :length="Math.ceil(workSamples.length / perPage)"
        total-visible="15"
      ></v-pagination>
    </v-bottom-navigation>
    <v-row no-gutters v-if="workSamples.length !== 0 || loadingData" style="margin-bottom: 100px">
      <v-card class="d-flex rounded-lg pa-4 mb-1 mx-1" width="100%" flat>
        <v-text-field
          hide-details
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('writeWhatYouSearch')"
          outlined
          dense
          class="ma-auto"
        >
          <template v-slot:append-outer>
            <v-card-actions style="margin-top: -19px" class="pt-0"> </v-card-actions>
          </template>
        </v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="orderByBool(true)"
              :color="orderBy == true && orderBy != null ? 'secondary' : 'grey'"
              dark
              fab
              class="ma-1"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-flag</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("portfolio.TagsItems.sortFlag") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="orderByBool(false)"
              :color="orderBy == false && orderBy != null ? 'yellow accent-4' : 'grey'"
              dark
              class="ma-1"
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-star</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("portfolio.TagsItems.sortAttention") }}</span>
        </v-tooltip>
        <v-btn tile color="primary" @click="back()" class="rounded-xl ma-1">
          {{ $t("back") }}
          <v-icon right> mdi-arrow-left </v-icon>
        </v-btn>
      </v-card>

      <v-col
        v-for="(item, index) in workSampleFilter"
        :key="index"
        class="px-0 py-1"
        cols="12"
        xl="3"
        lg="3"
        md="6"
        sm="12"
      >
        <v-card flat class="pa-0 mx-1">
          <v-card-title dir="auto" style="word-break: break-word; height: 85px; overflow: hidden"
            ><p>
              {{ item.title }}
            </p>
            <v-spacer />
            <v-menu bottom max-height="250">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  :to="{
                    name: 'portfolio.WorkSampleTable.details',
                    params: { id: item.workSampleGuid }
                  }"
                >
                  <v-list-item-title>{{
                    $t("portfolio.WorkSample.showWorkSample")
                  }}</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <edit-work-sample
                  v-if="isInRole(0)"
                  @putMethod="getWorkSamples()"
                  :id="item.workSampleGuid"
                />
                <remove-work-sample
                  v-if="isInRole(0)"
                  @deleteMethod="getWorkSamples()"
                  :id="item.workSampleGuid"
                  :title="item.title"
                />
              </v-list>
            </v-menu>
          </v-card-title>

          <v-row class="ma-0">
            <v-col cols="12">
              <div class="d-flex flex-wrap">
                <v-menu
                  bottom
                  absolute
                  left
                  origin="center"
                  transition="scale-transition"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          color="primary"
                          dark
                          small
                          class="ma-3"
                          @click="searchTag = null"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon left> mdi-pound </v-icon>
                          Tags
                        </v-btn>
                      </template>
                      <span>{{ $t("portfolio.TagsItem.title") }}</span>
                    </v-tooltip>
                  </template>

                  <v-list width="180px" max-width="100%">
                    <!-- <v-tooltip>
                      <template v-slot:activator="{ on: tooltip }"> -->
                    <!-- Fixed item -->
                    <v-list-item class="fixed-item">
                      <v-text-field
                        @input="searchTag(searchTag)"
                        :label="$t('portfolio.TagsItem.search')"
                        clearable
                        full-width
                        v-model="searchTag"
                        outlined
                      >
                      </v-text-field>
                    </v-list-item>

                    <!-- Scrollable items -->
                    <div class="scrollable-list overflow-x-hidden">
                      <v-list-item
                        bottom
                        v-for="(tag, i) in tagsFilter"
                        :key="i"
                        :title="tag.tagName"
                        @mouseenter="tag.hover = true"
                        @mouseover="tag.hover = true"
                        @mouseleave="tag.hover = false"
                        class="mb-2 mx-auto"
                        :style="
                          `;background-color: ${tag.tagColor}; border-radius:5px;
                      width:90% !important;text-align:center;${i == 0 ? 'margin-top:20px;' : ''}`
                        "
                        :color="tag.tagColor"
                      >
                        <v-list-item-title
                          @click="setTagToWorkSample(item.workSampleGuid, tag)"
                          class="text-truncate"
                          :style="
                            `color:${tag.tagTextColor} !important ;max-width:150px;cursor:pointer`
                          "
                          >{{ tag.tagName }}</v-list-item-title
                        >
                        <v-list-item-icon>
                          <edit-tag-item
                            :tag="tag"
                            :hover="tag.hover"
                            @putTagMethod="t => editTagItem(t)"
                            @setTagForDelete="n => setTag(n)"
                          ></edit-tag-item>
                        </v-list-item-icon>
                      </v-list-item>
                      <add-tag-item @postTagMethod="getTagsItem()"></add-tag-item>
                    </div>
                  </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <!-- {{ item.workSampleGuid }} -->
                <show-work-sample-tags
                  :id="item.workSampleGuid"
                  @removeTagToWorkSample="n => removeTagToWorkSample(n.sampleId, n.tag)"
                  @isOverlayOn="n => isOverlayOn(n.isOverlayOn)"
                ></show-work-sample-tags>
              </div>
            </v-col>
            <v-col class="pa-0">
              <v-card class="mx-1 d-flex flex-wrap align-start overflow-hidden" height="100" flat>
                <v-chip
                label
                  class="ma-1"
                  v-for="(tag, tagIndex) in item.tags"
                  :key="tagIndex"
                  :color="tag.tagColor"
                >
                  <p
                    class="pa-0 ma-0 text-truncate"
                    :style="`color:${tag.tagTextColor} ;max-width:80px !important;`"
                  >
                    {{ tag.tagName }}
                  </p>
                </v-chip>
              </v-card>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-chip>
              {{ item.createdDate | moment("YYYY/MM/DD - h:mm a ") }}
            </v-chip>
            <v-spacer />
            <v-btn icon small @click="ToggleFlag(item.workSampleGuid)">
              <v-icon class="" :color="item.flag ? 'secondary' : ''">mdi-flag</v-icon>
            </v-btn>
            <v-btn icon small @click="ToggleForAttention(item.workSampleGuid)">
              <v-icon class="" :color="item.forAttention ? 'yellow accent-4' : ''">mdi-star</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-card
      outlined
      color="transparent"
      class="mx-auto d-flex flex-column justify-center align-center"
      max-width="344"
      v-else
    >
      <v-avatar rounded width="200" height="200px" class="ma-auto">
        <v-icon size="100">mdi-close-network-outline</v-icon>
      </v-avatar>
      <v-card-title class="mb-5"> {{ $t("portfolio.messages.empty") }} </v-card-title>
      <!-- <add-work-sample v-if="isInRole(0)" @postMethod="getWorkSamples()" :empty="true" /> -->
    </v-card>
    <!-- <edit-tag-item
      @putTagMethod="(t) => editTagItem(t)"
      @closeTagEdit="dialog = false"
      :tag="tag"
      :dialog="dialog"
    >
    </edit-tag-item>


  -->
    <v-overlay z-index="99" absolute :value="overlay">
      <!-- <v-btn color="success" @click="overlay = false"> Hide Overlay </v-btn> -->
      <v-card color="primary" dark width="400" max-width="100%" class="pa-3 mb-5">
        <v-card-title>
          {{ $t("Processing") }}
          <v-progress-linear indeterminate color="white" class="mb-0 mt-2"></v-progress-linear>
        </v-card-title>
      </v-card>
    </v-overlay>
    <add-work-sample v-if="isInRole(0)" @postMethod="getWorkSamples()" :empty="false" />
    <delete-tag-item
      @closeTagDelete="dialog = false"
      @refreshTags="getTagsItem()"
      :tag="tag"
      :dialog="dialog"
    >
    </delete-tag-item>
  </v-container>
</template>

<script>
import DeleteTagItem from "../../components/portfolio/TagsItem/deleteTagItem.vue";
import ShowWorkSampleTags from "../../components/portfolio/TagsItem/showWorkSampleTags.vue";
import AddTagItem from "../../components/portfolio/TagsItem/addTagItem.vue";
import EditTagItem from "../../components/portfolio/TagsItem/editTagItem.vue";
import AddWorkSample from "../../components/portfolio/workSample/addWorkSample.vue";
import EditWorkSample from "../../components/portfolio/workSample/editWorkSample.vue";
import RemoveWorkSample from "../../components/portfolio/workSample/removeWorkSample.vue";

export default {
  data() {
    return {
      search: null,
      orderBy: null,
      searchTag: null,
      overlay: false,
      dialog: false,
      show: false,
      tag: {
        tagColor: null,
        tagItemGuid: null,
        tagName: null,
        tagTextColor: null,
        dialog: false,
        show: false,
        tagsLoading: false
      },

      swatches: [
        {
          hexa: "#02bdad",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#feae4c",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#df67ff",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#626262",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#f6b800",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#ff5a43",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#5cc0f5",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#dfdfdf",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#03cea4",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#512df6",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#3752af",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#5d03bd",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#0089ff",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#ff278f",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#a75f0c",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#d7e4ef",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#fededf",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#efe5ce",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#a2a2a2",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#ffe01a",
          selected: false,
          textColor: "#000000"
        }
      ],
      workSampleTags: [],
      workSamples: [],
      tags: [],
      loadingData: false,
      message: "",
      page: 1,
      perPage: 8
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    orderByBool(type) {
      this.orderBy = type === this.orderBy ? null : type;
      if (this.orderBy == null) {
        this.getWorkSamples();
        return;
      }
      this.workSamples.sort((a, b) => {
        if (a.flag && !b.flag && type === true) {
          return -1;
        } else if (a.forAttention && !b.forAttention && type === false) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    getWorkSamples() {
      this.loadingData = true;
      this.workSamples = [];
      this.axios
        .get("WorkSample/Get")
        .then(response => {
          const { data } = response.data;
          // console.log(response);
          if (Array.isArray(data)) {
            data.forEach(sample => {
              const tagsList = sample.tags;
              const tags = [];
              if (Array.isArray(tagsList)) {
                tagsList.forEach(obj => {
                  const switchItem = this.swatches.find(sw => sw.hexa === obj.tagColor);
                  const toAddTag = {
                    hover: false,
                    tagItemGuid: obj.tagItemGuid,
                    tagGuid: obj.tagGuid,
                    tagName: obj.tagName,
                    tagColor: obj.color,
                    tagTextColor: switchItem !== undefined ? switchItem.textColor : "white"
                  };
                  // console.log(toAddTag);
                  tags.push(toAddTag);
                });
              }
              const item = {
                workSampleGuid: sample.workSampleGuid,
                title: sample.title,
                tags: tags,
                forAttention: sample.forAttention,
                flag: sample.flag,
                menu: false,
                dialog: false,
                createdDate: sample.createdDate,
                student: sample.student
              };
              this.workSamples.push(item);
            });
            if (this.workSamples.length === 0) {
              this.$t("portfolio.messages.empty");
            }
          }
        })
        .catch(err => {
          console.log("error:", err);
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    getTagsItem() {
      this.tagsLoading = true;
      this.tags = [];
      this.axios
        .get("TagItem/Get")
        .then(response => {
          const { data } = response.data;
          // console.log(response.data);
          if (Array.isArray(data)) {
            data.forEach(sample => {
              // eslint-disable-next-line array-callback-return
              const color = this.swatches.find(x => {
                if (
                  sample.tagColor !== undefined &&
                  sample.tagColor !== "undefined" &&
                  x.hexa === sample.tagColor
                ) {
                  return x;
                }
              });
              // console.log(color);
              const tag = {
                tagColor: sample.tagColor,
                tagItemGuid: sample.tagItemGuid,
                tagName: sample.tagName,
                tagTextColor: color !== undefined ? color.textColor : "white",
                hover: false
              };
              this.tags.push(tag);
            });
            if (this.tags.length === 0) {
              this.$t("portfolio.messages.empty");
            }
          }
        })
        .catch(err => {
          console.log("error:", err);
        })
        .finally(() => {
          this.tagsLoading = false;
        });
    },
    editTagItem(tag) {
      // console.log("Edited");
      // eslint-disable-next-line prefer-const
      let object = this.tags.find(x => x.tagItemGuid === tag.tagItemGuid);
      if (object) {
        object.tagName = tag.tagName;
        object.tagColor = tag.tagColor;
        object.tagTextColor = tag.textColor;
      }
      // Iterate through the workSamples array
      this.workSamples.forEach(workSample => {
        // Check if the workSample has a tags array
        if (Array.isArray(workSample.tags)) {
          // Find the tag with the same tagItemGuid in the workSample's tags array
          const workSampleTag = workSample.tags.find(x => x.tagItemGuid === tag.tagItemGuid);

          // Update the tag in the workSample's tags array if found
          if (workSampleTag) {
            workSampleTag.tagName = tag.tagName;
            workSampleTag.tagColor = tag.tagColor;
            workSampleTag.tagTextColor = tag.textColor;
          }
        }
      });
    },
    openTagItemEdit(item) {
      this.tag.tagItemGuid = item.tagItemGuid;
      this.tag.tagColor = item.tagColor;
      this.tag.tagName = item.tagName;
      this.tag.tagTextColor = item.tagTextColor;
      // this.tag = item;
      this.dialog = true;
    },
    setTag(item) {
      this.tag.tagColor = item.tagColor;
      this.tag.tagItemGuid = item.tagItemGuid;
      this.tag.tagTextColor = item.tagTextColor;
      this.tag.tagName = item.tagName;
      this.dialog = true;
    },
    async setTagToWorkSample(sampleId, tag) {
      const workSample = this.workSamples.find(s => s.workSampleGuid === sampleId);

      if (workSample) {
        const { tags } = workSample;

        if (Array.isArray(tags)) {
          const exists = tags.some(obj => tag.tagItemGuid === obj.tagItemGuid);
          if (!exists) {
            this.overlay = true;

            // Add the tag to the array if it's not already present
            const switchItem = this.swatches.find(sw => sw.hexa === tag.tagColor);
            const toAddTag = {
              tagItemGuid: tag.tagItemGuid,
              tagName: tag.tagName,
              tagColor: tag.tagColor,
              hover: false,
              tagTextColor: switchItem !== undefined ? switchItem.textColor : "white"
            };
            await this.axios
              .post("Tag/Create", {
                tagItemGuid: tag.tagItemGuid,
                workSampleGuid: sampleId
              })
              .then(result => {
                // console.log(result);
                if (result.data.details === "Tag Already Exist.") {
                  this.overlay = false;
                  this.$swal.fire({
                    title: this.$t("portfolio.TagsItems.AlreadyExist"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: toast => {
                      toast.addEventListener("mouseenter", this.$swal.stopTimer);
                      toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    }
                  });
                  return;
                }
                if (Array.isArray(tags)) {
                  tags.push(toAddTag);
                }
                this.overlay = false;
                this.$swal.fire({
                  title: this.$t("operationAccomplishedSuccessfully"),
                  text: "",
                  icon: "success",
                  confirmButtonText: this.$t("close"),
                  toast: true,
                  position: "bottom-start",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: toast => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                  }
                });
              })
              .catch(() => {
                this.overlay = false;
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                  toast: true,
                  position: "bottom-start",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: toast => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                  }
                });
              });
            // setTimeout(() => {
            //   tags.push(toAddTag);
            //   this.overlay = false;
            // }, 300);
          }
        }
      }
    },
    removeTagToWorkSample(sampleId, tag) {
      const workSample = this.workSamples.find(s => s.workSampleGuid === sampleId);
      // alert("Del");
      if (workSample) {
        const { tags } = workSample;

        if (Array.isArray(tags)) {
          const exists = tags.some(obj => tag.tagItemGuid === obj.tagItemGuid);
          console.log(exists, tags);
          if (exists) {
            const tagItem = tags.findIndex(sw => sw.tagItemGuid === tag.tagItemGuid);
            tags.splice(tagItem, 1);
          }
        }
      }
    },
    isOverlayOn(isOn) {
      this.overlay = isOn;
    },
    async ToggleForAttention(id) {
      this.overlay = true;
      const workSample = this.workSamples.find(s => s.workSampleGuid === id);
      await this.axios
        .post(`WorkSample/ToggleForAttention?worksampleGuid=${id}`)
        .then(response => {
          workSample.forAttention = response.data;
          this.overlay = false;
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
        })
        .catch(() => {
          this.overlay = false;
          this.$swal
            .fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .finally(() => {
              this.overlay = false;
            });
        });
    },
    async ToggleFlag(id) {
      this.overlay = true;
      const workSample = this.workSamples.find(s => s.workSampleGuid === id);
      await this.axios
        .post(`WorkSample/ToggleFlag?worksampleGuid=${id}`)
        .then(response => {
          workSample.flag = response.data;
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.$swal
            .fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .finally(() => {
              this.overlay = false;
            });
        });
    }
  },
  created() {
    this.getWorkSamples();
    this.getTagsItem();
  },
  components: {
    AddWorkSample,
    EditWorkSample,
    RemoveWorkSample,
    DeleteTagItem,
    // EditTagItem,
    AddTagItem,
    ShowWorkSampleTags,
    EditTagItem
  },
  computed: {
    visiblePages() {
      return this.workSampleFilter.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
    workSampleFilter() {
      const data = this.workSamples;

      // if (this.isLiveOnly) {
      //   data = data.filter((item) => item.isLive === true);
      // }

      if (this.search) {
        return data.filter(
          item =>
            // eslint-disable-next-line implicit-arrow-linebreak
            this.search
              .toLowerCase()
              .split(" ")
              .every(v => item.title.toLowerCase().includes(v))
          // eslint-disable-next-line function-paren-newline
        );
      } else {
        return data;
      }
    },
    tagsFilter() {
      const data = this.tags;

      // if (this.isLiveOnly) {
      //   data = data.filter((item) => item.isLive === true);
      // }

      if (this.searchTag) {
        return data.filter(
          item =>
            // eslint-disable-next-line implicit-arrow-linebreak
            this.searchTag
              .toLowerCase()
              .split(" ")
              .every(v => item.tagName.toLowerCase().includes(v))
          // eslint-disable-next-line function-paren-newline
        );
      } else {
        return data;
      }
    }
  }
};
</script>
<style scoped>
#tagSearch {
  width: 150px !important;
  max-width: 100% !important;
}
#tagSearch >>> * {
  font-size: 14px !important;
}

.fixed-item {
  display: flex;
  height: 10px;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 30px;
  /* Optional, add background color as needed */
  z-index: 1; /* Optional, adjust z-index as needed */
  /* Add any other styles for your fixed item */
}

.scrollable-list {
  z-index: 2;
  margin-top: 70px;
  max-height: 250px; /* Set the maximum height for scrollable list */
  overflow-y: auto; /* Add a vertical scrollbar if necessary */
  /* Add any other styles for your scrollable list */
}
</style>
